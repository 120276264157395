import { v4 as uuidv4 } from 'uuid';

export function useOptimizely() {
  const { $optimizelyClient } = useNuxtApp();
  const optCookie = useCookie('opt_id', {
    maxAge: 60 * 60 * 24 * 90,
    path: '/',
  });

  const optVariation = useCookie(`opt_variation`, {
    maxAge: 60 * 60 * 24 * 90,
    path: '/',
  });

  function optimizelyContentfulExp(ctflObj) {
    const idGen = uuidv4();
    const userId = optCookie.value ? optCookie.value : idGen;
    if (optCookie.value === undefined) {
      optCookie.value = idGen;
    }

    const feature = ctflObj.experimentKey.replace('_test', '');

    return $optimizelyClient
      .onReady()
      .then(() => {
        const userVariation = optVariation.value ? optVariation.value : null;
        const enabled = $optimizelyClient.isFeatureEnabled(feature, userId);
        let variation;

        if (userVariation) {
          $optimizelyClient.setForcedVariation(
            ctflObj.experimentKey,
            userId,
            userVariation
          );
          // activate() HAS to be called after setting the variation otherwise it won't be forced
          // https://docs.developers.optimizely.com/full-stack-experimentation/docs/set-forced-variation-javascript
          /* Forces a user into a variation for a given experiment for the lifetime of the 
          Optimizely client. Any future calls to Activate, Is Feature Enabled, Get Feature Variable, 
          and Track for the given user ID returns the forced variation. */
          variation = $optimizelyClient.activate(ctflObj.experimentKey, userId);
        } else {
          variation = $optimizelyClient.activate(ctflObj.experimentKey, userId);
          const variationKey = $optimizelyClient.getVariation(
            ctflObj.experimentKey,
            userId
          );
          optVariation.value = variationKey;
        }
        return { ctflKey: ctflObj.meta[variation], variation };
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function isExpRunning(ctflObj) {
    if (optCookie.value) {
      return $optimizelyClient.isFeatureEnabled(
        ctflObj.experimentKey,
        optCookie.value
      );
    } else {
      return false;
    }
  }

  function trackOptEvent(event, userId) {
    $optimizelyClient.track(event, userId);
  }

  return {
    optimizelyContentfulExp,
    isExpRunning,
    trackOptEvent,
  };
}
